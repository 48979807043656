import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import { Accordion, AccordionItem } from '@jsluna/accordion';
import { SearchButton } from '@jsluna/button';
import { NectarFlatPurple } from '@jsluna/images';
import { TextInputField, SearchInput } from '@jsluna/form';
import TextImagesDONT from '@images/a11y/1.1-Text-Images-DONT.png';
import LabelInputsDO from '@images/a11y/1.10-Label-Inputs-DO.png';
import LabelInputsDONT from '@images/a11y/1.10-Label-Inputs-DONT.png';
import HiddenLabelDO from '@images/a11y/1.5-Hidden-Label-DO.png';
import HiddenLabelDONT from '@images/a11y/1.5-Hidden-Label-DONT.png';
import TextalternativesDO from '@images/a11y/1.2-Text-alternatives-DO.png';
import TextalternativesDONT from '@images/a11y/1.2-Text-alternatives-DONT.png';
import TextalternativesDO2 from '@images/a11y/1.2-Text-alternatives-DO_2.png';
import TextalternativesDONT2 from '@images/a11y/1.2-Text-alternatives-DONT_2.png';
import TextalternativesDO4 from '@images/a11y/1.2-Text-alternatives-DO_4.png';
import TextalternativesDONT4 from '@images/a11y/1.2-Text-alternatives-DONT_4.png';
import FocusStatesDO from '@images/a11y/1.4-Focus-States-DO.png';
import FocusStatesDONT from '@images/a11y/1.4-Focus-States-DONT.png';
import ReadingFocusOrderDO from '@images/a11y/1.5-Reading-Focus-Order-DO.png';
import ReadingFocusOrderDONT from '@images/a11y/1.5-Reading-Focus-Order-DONT.png';
import HeadingsDO from '@images/a11y/1.6-Headings-DO.png';
import HeadingsDONT from '@images/a11y/1.6-Headings-DONT.png';
import ResponsiveDO from '@images/a11y/1.7-Responsive-DO.png';
import ResponsiveDONT from '@images/a11y/1.7-Responsive-DONT.png';
import ColourContrastDO from '@images/a11y/1.8-ColourContrast-DO.png';
import ColourContrastDONT from '@images/a11y/1.8-ColourContrast-DONT.png';
import UIColourContrastDO from '@images/a11y/1.8-UIColourContrast-DO.png';
import UIColourContrastDONT from '@images/a11y/1.8-UIColourContrast-DONT.png';
import ColourAloneDO from '@images/a11y/1.9-Colour-Alone-DO.png';
import ColourAloneDONT from '@images/a11y/1.9-Colour-Alone-DONT.png';
import ColourAloneDO2 from '@images/a11y/2.4-Colour-Alone-DO_2.png';
import ColourAloneDONT2 from '@images/a11y/2.4-Colour-Alone-DONT_2.png';
import PlaceholderTextDO from '@images/a11y/PlaceholderText-DO.png';
import PlaceholderTextDONT from '@images/a11y/PlaceholderText-DONT.png';
import SupportLargeFontDO from '@images/a11y/SupportLargeFont-DO.png';
import SupportLargeFontDONT from '@images/a11y/SupportLargeFont-DONT.png';
import { OutlineExampleDont, OutlineExampleDo } from '@components/accessibility-sections/OutlineExample';
import { ColourContrast, ImagesOfText, KeyboardTasks, LeftAlignParagraphs, ResponsiveLayout, SemanticMarkup, SentenceCase } from '@components/accessibility-sections';
import { default as CodeSnippet } from '@components/accessibility-sections/CodeSnippet';
import ResponsiveLayoutContent from '@shared/accessibility/responsive-layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const Section = makeShortcode("Section");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const Display2 = makeShortcode("Display2");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#web-standards-for-designing"
            }}>{`Web standards for designing`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#web-standards-for-developing"
            }}>{`Web standards for developing`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <p>{`Whether you’re part of a team designing web experiences or part of a team building web experiences, we encourage you to work together to deliver accessible products.`}</p>
    <p>{`The following web standards are based on the `}<a href="https://www.w3.org/TR/WCAG22/" target="_blank">{`Web Content Accessibility Guidelines (WCAG 2.2)`}</a>{`. We've taken the guidance from WCAG 2.2 and made it more specific to the kind of web experiences we design and build for our Sainsbury's brands.`}</p>
    <p>{`For support making products accessible or to provide some feedback, `}<a parentName="p" {...{
        "href": "#get-in-touch"
      }}>{`get in touch with us`}</a>{`.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "web-standards-for-designing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#web-standards-for-designing",
        "aria-label": "web standards for designing permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Web standards for designing`}</h2>
    <p>{`Make sure to follow these standards by annotating your designs with relevant accessibility information before you hand them over to the team building your web experiences.`}</p>
    <h3>{`Things to check`}</h3>
    <Section mdxType="Section">
  <Accordion titleElement="h3" mdxType="Accordion">
    <AccordionItem title="1.1 Left align blocks of text" mdxType="AccordionItem">
      <p>
        Left align blocks of text for an easier reading experience. Avoid using
        justified or centre aligned text, unless it’s a heading or a control
        label that fits on one line.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <div className="ln-u-border ln-u-soft">
            <p>This section of text is left aligned.</p>
            <p>
              The start of each line and the spacing between words is
              predictable and consistent which makes scanning and reading
              easier.
            </p>
          </div>
        </Do>
        <Dont mdxType="Dont">
          <div className="ln-u-border ln-u-soft">
            <p className="ln-u-text-align-center">
              This section of text is centre aligned.
            </p>
            <p className="ln-u-text-align-center">
              The start of each line is unpredictable which makes scanning and
              reading harder and can be very disorientating for some users.
            </p>
          </div>
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.2 Use sentence case text" mdxType="AccordionItem">
      <p>
        The shape of sentence case words are distinct and familiar, making them
        easier to read. Avoid using uppercase text, as it takes longer to read
        and process.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            This sentence is written in sentence case. The words have a distinct
            shape and are easily recognisable.
          </p>
        </Do>
        <Dont mdxType="Dont">
          <p>
            THIS SENTENCE IS WRITTEN IN UPPERCASE. THE WORDS HAVE A UNIFORMED
            RECTANGULAR SHAPE WHICH MAKES THEM HARDER TO DISTINGUISH.
          </p>
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.3 Don't use images of text" mdxType="AccordionItem">
      <p>
        An image is a collection of pixels that contain no information, whereas
        text can be highlighted, copied and read by screen readers, search
        engines and other assistive technologies.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <Display2 element="h3" mdxType="Display2">This is real text</Display2>
          <p>It can be highlighted, copied and read out by a screen reader</p>
        </Do>
        <Dont mdxType="Dont">
          <img src={TextImagesDONT} alt="This is an image of text, it can't be highlightedc copied or read out by a screen reader. It also needs a text alternative" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.4 Check the colour contrast meets WCAG 2.2 AA" mdxType="AccordionItem">
      <p>
        The colours you're using should have sufficient colour contrast to make
        text and UI controls as easy as possible to read and distinguish. As a
        minimum, we follow WCAG 2.2 AA standard contrast ratios. WCAG 2.2 level
        AA requires a contrast ratio of at least 4.5:1 for normal text and 3:1
        for large text.
      </p>
      <p>
        To check if the colour contrast of your elements meets the AA standard,
        the{' '}
        <a href="https://webaim.org/resources/contrastchecker/" target="_blank">
          WebAIM colour contrast checker
        </a>{' '}
        is one of the many tools available to check colour contrast.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <img src={ColourContrastDO} alt="Dark text on a white background with a contrast ratio of 21:1" />
        </Do>
        <Dont mdxType="Dont">
          <img src={ColourContrastDONT} alt="Light text on a white background with a contrast ratio of 1.42:1" />
        </Dont>
      </Guideline>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <img src={UIColourContrastDO} alt="Dark outlined checkbox component on a white background with a contrast ratio of 21:1" />
        </Do>
        <Dont mdxType="Dont">
          <img src={UIColourContrastDONT} alt="Harder to see light outlined checkbox component on a white background with a contrast ratio of 21:1" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.5 Don't convey information using only colour" mdxType="AccordionItem">
      <p>
        Use additional visual cues in combination with colour to convey
        information so users with vision impairments, or who are colourblind,
        can have difficulty distinguishing colours.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <img src={ColourAloneDO} alt="A paragraph of text with a bold blue underlined link in it" />
        </Do>
        <Dont mdxType="Dont">
          <img src={ColourAloneDONT} alt="A paragraph of text with with a link in it which is only identifiable as a link by its colour" />
        </Dont>
      </Guideline>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <img src={ColourAloneDO2} alt="A form field that uses colour, an icon, and a text explanation for an error" />
        </Do>
        <Dont mdxType="Dont">
          <img src={ColourAloneDONT2} alt="A form field that uses just colour to show something is wrong" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.6 Factor in large font settings into your designs" mdxType="AccordionItem">
      <p>
        All text should also be able to be resized using the largest browser
        font-size setting without losing information or meaning.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            Think about how your designs will need to respond to large font
            settings
          </p>
          <img src={SupportLargeFontDO} alt="two buttons, one with smaller text one with bigger text" />
        </Do>
        <Dont mdxType="Dont">
          <p>
            Don't assume all layouts and elements will will work with large font
            settings
          </p>
          <img src={SupportLargeFontDONT} alt="The larger text button is poorly formatted, the word action is split across two lines and the spacing around the text is incorrect." />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.7 Make sure content is responsive at all breakpoints" mdxType="AccordionItem">
      <p>
        Consider all the different breakpoints of your content and make sure
        that all content is responsive throughout.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <img src={ResponsiveDO} alt="A list of products presented as a horizontal list on a wider viewport and a vertical list on a smaller viewport" />
        </Do>
        <Dont mdxType="Dont">
          <img src={ResponsiveDONT} alt="A single viewport showing a horizontal list of products" />
        </Dont>
      </Guideline>
    </AccordionItem>
  </Accordion>
    </Section>
    <h3>{`Things to annotate`}</h3>
    <Section mdxType="Section">
  <Accordion titleElement="h3" mdxType="Accordion">
    <AccordionItem title="1.8 Give text alternatives for icons and images" mdxType="AccordionItem">
      <p>
        When there is no supporting text, provide text alternatives for images
        and icons to convey meaning for screen reader users. Hide decorative
        images that are not needed to understand content.
      </p>
      <p>
        If you're not sure whether an image needs a text alternative, you can{' '}
        <a href="https://www.w3.org/WAI/tutorials/images/decision-tree/" target="_blank">
          refer to the W3C alt decision tree
        </a>{' '}
        for help.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            Annotate text alternatives for images and icons when there is no
            supporting text for the image or icon
          </p>
          <img src={TextalternativesDO} alt="A design of a retail product card including text alternative annotations" />
        </Do>
        <Dont mdxType="Dont">
          <p>
            Without text alternatives the meaning of your images and icons can
            be lost or misinterpreted
          </p>
          <img src={TextalternativesDONT} alt="A design of a retail product card with no text alternative annotations" />
        </Dont>
      </Guideline>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <img src={TextalternativesDO2} alt="Heart icon with a text alternative annotation of add to wishlist" />
        </Do>
        <Dont mdxType="Dont">
          <img src={TextalternativesDONT2} alt="Heart icon with no text alternative annotations" />
        </Dont>
      </Guideline>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            The delivery van is decorative, it's not needed for users to
            understand the adjacent information so it can be hidden from screen
            reader users
          </p>
          <img src={TextalternativesDO4} alt="A delivery van image with the adjacent text, Save up to £168 per year, Pay no delivery charges 7 days a week" />
        </Do>
        <Dont mdxType="Dont">
          <p>
            Don't leave it up to others to guess if an image should or shouldn't
            have a text alternative.
          </p>
          <img src={TextalternativesDONT4} alt="A design of a retail product card with no text alternative annotations" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.9 Always provide a visible form field label" mdxType="AccordionItem">
      <p>
        Form fields should have ever present labels to help users avoid errors
        when entering data. Placeholder text disappears when a user starts
        typing so should not be used as the only source of information to the
        user.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <img src={PlaceholderTextDO} alt="Form field a label of First Name visible above the field, with the placeholder text, Example: Mary-Ann" />
        </Do>
        <Dont mdxType="Dont">
          <img src={PlaceholderTextDONT} alt="Form field with placeholder text, First name" />
        </Dont>
      </Guideline>
      <p>
        Input fields should always be accompanied with an visible label.
        However, if it isn't possible to display a visible label then ensure you
        provide an appropriate hidden label for assistive technologies like
        screen readers so they can understand and use the field.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>Provide an annotation for hidden labels</p>
          <img src={HiddenLabelDO} alt="Form field with no visible label with placeholder text, search our products... There is an annotation for the hidden label" />
        </Do>
        <Dont mdxType="Dont">
          <p>Don't leave it up to the developer to figure out what you want</p>
          <img src={HiddenLabelDONT} alt="Form field with no visible label with placeholder text, search our products... There is no annotation for the form field label" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.10 Communicate the intended reading and focus order" mdxType="AccordionItem">
      <p>
        Users are used to reading in a conventional and predictable order, so
        breaking the order and jumping around can be disorientating, distracting
        and confusing.
      </p>
      <p>
        Users who are using screen magnification, keyboard navigation, or screen
        readers may interpret information in the wrong context if the focus
        order isn't logical, so communicate the reading order of elements in
        your designs.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>Annotate the intended reading and focus order in your designs</p>
          <img src={ReadingFocusOrderDO} alt="A product card clearly showing the intended reading and focus order of the elements within it" />
        </Do>
        <Dont mdxType="Dont">
          <p>
            Without annotations the intended flow of content can be interpreted
            incorrectly
          </p>
          <img src={ReadingFocusOrderDONT} alt="A product card with no indication of the intended reading and focus order of the elements within it" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.11 Communicate your content with headings h1 to h6" mdxType="AccordionItem">
      <p>
        Using the H1 to H6 heading structure will help screen reader users more
        efficiently navigate and identify sections of content. Headings also
        create a visual hierarchy of information that helps all visual users
        scan through content.
      </p>
      <p>
        If you're unsure about heading structures, you can{' '}
        <a href="https://www.w3.org/WAI/tutorials/page-structure/headings/" target="_blank">
          read more about using headings on the W3C
        </a>
        .
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            Annotate your headings structurally using the h1 to h6 hierarchy
          </p>
          <img src={HeadingsDO} alt="A product list with an h1 heading and h3 headings for each product in the list" />
        </Do>
        <Dont mdxType="Dont">
          <p>Without annotations your headings can be built incorrectly</p>
          <img src={HeadingsDONT} alt="A product list with no heading annotations" />
        </Dont>
      </Guideline>
    </AccordionItem>
  </Accordion>
    </Section>
    <h2 {...{
      "id": "web-standards-for-developing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#web-standards-for-developing",
        "aria-label": "web standards for developing permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Web standards for developing`}</h2>
    <p>{`By following these standards when building for web, we can help deliver accessible web experiences for our users.`}</p>
    <Section mdxType="Section">
  <Accordion titleElement="h3" mdxType="Accordion">
    <AccordionItem title="2.1 Use semantic content sectioning elements with correct ARIA roles" mdxType="AccordionItem">
      <p>
        Use semantic <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element#content_sectioning" target="_blank">sectioning elements</a> like <code>header</code>, <code>nav</code>, <code>main</code>, <code>footer</code>
        and <a href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/landmark_role" target="_blank">ARIA landmark roles</a> to create document structure so screen reader users can easily understand and efficiently navigate the content on the page.
      </p>
      <Guideline hasCodeSnippet mdxType="Guideline">
        <Do hasCodeSnippet mdxType="Do">
          <p className="ln-u-soft-sides">Use semantic HTML</p>
          <CodeSnippet codeFormat="html" code={`<header role="banner">
                   <h1>Using ARIA landmarks</h1>
                   <nav role="nav">...</nav>
                   </header>
                     <main>...</main>
                   <footer role="contentinfo">...</footer>`} mdxType="CodeSnippet" />
        </Do>
        <Dont hasCodeSnippet mdxType="Dont">
          <p className="ln-u-soft-sides">Don't use generic elements in place of semantic alternatives</p>
          <CodeSnippet codeFormat="html" code={`<div>
                   <span>Using ARIA landmarks</span>
                   <div>...</div>
                   </div>
                     <div>...</div>
                   <div>...</div>`} mdxType="CodeSnippet" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.2 Structure content with nested heading elements" mdxType="AccordionItem">
      <p>
        Use <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements" target="_blank">HTML section heading elements</a> (<code>H1</code> to <code>H6</code>) to structure
        content so screen reader users can easily navigate and identify sections
        of content.
      </p>
      <Guideline mdxType="Guideline">
        <Do hasCodeSnippet mdxType="Do">
          <p className="ln-u-soft-sides">Use HTML heading elements h1 - h6</p>
          <CodeSnippet codeFormat="html" code={`<h1>Page heading</h1>
                   <h2>Page section heading</h2>
                   <h3>Sub section heading</h3>
                   <h2>Page section heading</h2>`} mdxType="CodeSnippet" />
        </Do>
        <Dont hasCodeSnippet mdxType="Dont">
          <p className="ln-u-soft-sides">Don't style non-semantic elements to look like headings</p>
          <CodeSnippet codeFormat="html" code={`<div style="font-weight:bold; font-size:24px;">Page heading</div>
                   <div style="font-weight:bold; font-size:18px">Page section heading</div>`} mdxType="CodeSnippet" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.3 Announce errors, status changes and content updates" mdxType="AccordionItem">
      <p>
        Use the <a href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alert_role" target="_blank">ARIA alert</a> role or the <a href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions" target="_blank">aria-live</a> attribute to announce dynamic changes in content to screen reader users who cannot see them.
      </p>
      <Guideline mdxType="Guideline">
          <Do hasCodeSnippet mdxType="Do">
          <p className="ln-u-soft-sides">The <code>aria-live</code> attribute will announce the error message to assistive technologies when it is injected into the DOM</p>
          <CodeSnippet codeFormat="html" code={`<div role="alert" aria-live="assertive" id="validation">
                   <p>This error...</p>
                   </div>
                   <input aria-describedby="validation" aria-invalid="true" />`} mdxType="CodeSnippet" />
        </Do>
        <Dont hasCodeSnippet mdxType="Dont">
          <p className="ln-u-soft-sides">This error message will not be announced</p>
          <CodeSnippet codeFormat="html" code={`<div>
                   <p>This error...</p>
                   </div>
                   <input />`} mdxType="CodeSnippet" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.4 Make sure all elements have the correct roles, states and properties" mdxType="AccordionItem">
      <p>
        <a href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques" target="_blank">Roles, states and properties</a> provide important information to assistive technology users so they can understand what type of element it is and how to use it.
      </p>
      <Guideline mdxType="Guideline">
        <Do hasCodeSnippet mdxType="Do">
          <p className="ln-u-soft-sides">Assign an appropriate ARIA role and correct states and properties to elements, unless an element already has the appropriate semantics</p>
          <CodeSnippet codeFormat="html" code={`
            <!-- a switch control needs additional properties and states -->
            <button
            type="button"
            role="switch"
            aria-checked="true"
            id="subscribe">
              <span aria-hidden="true">off</span>
              <span aria-hidden="true">on</span>
            </button>
            <label for="subscribe" class="switch">Subscribe</label>`} mdxType="CodeSnippet" />
        </Do>
        <Dont hasCodeSnippet mdxType="Dont">
          <p className="ln-u-soft-sides">Don't omit important semantic information that could block or confuse assistive technology users from using the element</p>
          <CodeSnippet codeFormat="html" code={`
            <!-- this is just a button -->
            <button
            type="button"
            id="subscribe">
            </button>
            <label for="subscribe" class="switch">Subscribe</label>`} mdxType="CodeSnippet" />
         </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.5 Define the human language of the document" mdxType="AccordionItem">
      <p>
        Declaring the language of the document using the correct <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/lang" target="_blank">HTML lang</a> attribute tells the device what language and accent a screen reader should read in.
      </p>
      <Guideline mdxType="Guideline">
          <Do hasCodeSnippet mdxType="Do">
          <p className="ln-u-soft-sides">Declare the language of the document. <code>en-GB</code> will result in content being read out in British English</p>
          <CodeSnippet codeFormat="html" code={`<html lang="en-GB">`} mdxType="CodeSnippet" />
        </Do>
        <Dont hasCodeSnippet mdxType="Dont">
          <p className="ln-u-soft-sides">Don't leave the document language undefined</p>
          <CodeSnippet codeFormat="html" code={`<html okay>`} mdxType="CodeSnippet" />
         </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.6 Don't remove visible focus states for focusable elements" mdxType="AccordionItem">
      <p>
         All buttons links and controls require a visible focus state because it lets users who don't use a mouse know where they are on the page.
      </p>
      <Guideline mdxType="Guideline">
          <Do hasCodeSnippet mdxType="Do">
          <p className="ln-u-soft-sides">Let the browser handle visible focus</p>
          <CodeSnippet codeFormat="css" code={`:focus {outline: 2px solid;} `} mdxType="CodeSnippet" />
        </Do>
        <Dont hasCodeSnippet mdxType="Dont">
          <p className="ln-u-soft-sides">Don't visually hide focus states</p>
          <CodeSnippet codeFormat="css" code={`:focus {outline: 0;} `} mdxType="CodeSnippet" />
         </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.7 All form fields must have an accessible name" mdxType="AccordionItem">
      <p>
        Form fields and controls need a programmatic label so that assistive
        technology users can identify and correctly use them.
      </p>
      <p>
        Preferably use the <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label" target="_blank">label</a> element and <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label#attr-for" target="_blank">for</a> attribute for programmatically connecting form controls.
      </p>  
      <Guideline mdxType="Guideline">
        <Do hasCodeSnippet mdxType="Do">
          <p className="ln-u-soft-sides">Always programatically connect a form field to it's visible or non-visible label</p>
          <CodeSnippet codeFormat="html" code={`
            <form>
            <label class="ln-c-label" for="search">Search</label>
            <input type="text" id="search" />
            </form>`} mdxType="CodeSnippet" />
        </Do>
        <Dont hasCodeSnippet mdxType="Dont">
          <p className="ln-u-soft-sides">Don't rely on visual relationships between controls and their labels, they must be programmatically explicit</p>
          <CodeSnippet codeFormat="html" code={`
              <form>
              <div class="ln-c-label">Search</div>
              <input type="text" />
              </form>`} mdxType="CodeSnippet" />
         </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.8 All buttons and links must have an accessible name" mdxType="AccordionItem">
      <p>
        Buttons, links, and form controls must have an accessible name so that assistive technology users know what to do with them.
      </p>
      <Guideline mdxType="Guideline">
        <Do hasCodeSnippet mdxType="Do">
          <p className="ln-u-soft-sides">Ensure links and buttons have a meaningful accessible name</p>
          <CodeSnippet codeFormat="html" code={`
            <button>Search products</button> `} mdxType="CodeSnippet" />
        </Do>
        <Dont hasCodeSnippet mdxType="Dont">
          <p className="ln-u-soft-sides">Don't remove or omit accessible names</p>
          <CodeSnippet codeFormat="html" code={`
            <button>?</button> `} mdxType="CodeSnippet" />
         </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.9 All buttons links and controls must be keyboard accessible" mdxType="AccordionItem">
      <p>
        All interactive elements that can be actioned using touch or a mouse must also receive focus and be actionable through a keyboard.
      </p>
      <Guideline mdxType="Guideline">
        <Do hasCodeSnippet mdxType="Do">
          <p className="ln-u-soft-sides">Semantic controls are inherently keyboard accessible</p>
          <CodeSnippet codeFormat="html" code={`
              <!-- focusable and actionable with a keyboard -->
              <button>My button</Button> `} mdxType="CodeSnippet" />
        </Do>
        <Dont hasCodeSnippet mdxType="Dont">
          <p className="ln-u-soft-sides">Don't remove controls from the focus order or only make them respond to click events</p>
          <CodeSnippet codeFormat="html" code={`
              <!-- removed from the focus order -->
              <button tabindex="-1">My button</button>
              <!-- only responds to click events -->
              <div id="clickable-button" onclick="sayHello()">My button</div> `} mdxType="CodeSnippet" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.10 Focusable elements should follow a logical reading order" mdxType="AccordionItem">
      <p>
        Elements that receive focus should do so in a predictable sequence, typically from left to right and top to bottom. The default reading order is determined by the order of the focusable elements in the DOM.
      </p>
      <Guideline mdxType="Guideline">
        <Do hasCodeSnippet mdxType="Do">
          <p className="ln-u-soft-sides">Structure the DOM to match the reading order</p>
          <CodeSnippet codeFormat="html" code={`
              <button>First button</button>
              <button>Second button</button> 
              <button>Third button</button> `} mdxType="CodeSnippet" />
        </Do>
        <Dont hasCodeSnippet mdxType="Dont">
          <p className="ln-u-soft-sides">Don't provide an unpredictable reading order</p>
          <CodeSnippet codeFormat="html" code={`
               <!-- tabindex of 1 will make the 3rd button the first focusable element -->
              <button>1st button</button>
              <button>2nd button</button> 
              <button tabindex="1">3rd button</button> `} mdxType="CodeSnippet" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.11 Define font sizes and text container dimensions in relative units to support font scaling" mdxType="AccordionItem">
      <p>
        All text should be resizable using browser font settings without the text being clipped, truncated, overlapped or obscured.
      </p>
      <Guideline mdxType="Guideline">
        <Do hasCodeSnippet mdxType="Do">
          <p className="ln-u-soft-sides">Use relative units for text and containers</p>
          <CodeSnippet codeFormat="css" code={` button {font-size: 2rem; width: 100%} `} mdxType="CodeSnippet" />
        </Do>
        <Dont hasCodeSnippet mdxType="Dont">
          <p className="ln-u-soft-sides">Don't provide fixed width, height and font sizes</p>
          <CodeSnippet codeFormat="css" code={` button {font-size: 12px; width: 50px} `} mdxType="CodeSnippet" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.12 Content can be presented at a width of 320 CSS pixels without horizontal scrolling" mdxType="AccordionItem">
      <p>
        Allow users to zoom into content without losing information or functionality. 320 CSS pixels is equivalent to a starting viewport width of 1280 CSS pixels wide at 400% zoom.
      </p>
      <Guideline mdxType="Guideline">
        <Do hasCodeSnippet mdxType="Do">
          <p className="ln-u-soft-sides">Use relative units for text and containers</p>
          <CodeSnippet codeFormat="html" code={`
              <ul style="width:100%">
              <li>...</li>
              <li>...</li>
              <li>...</li>
              </ul>`} mdxType="CodeSnippet" />
        </Do>
        <Dont hasCodeSnippet mdxType="Dont">
          <p className="ln-u-soft-sides">Don't disable zoom or provide a minimum-width greater that 320 CSS pixels </p>
          <CodeSnippet codeFormat="html" code={`
              <head>
              <!-- disables zoom -->
              <meta name="viewport" content="user-scalable=no"
              </head>
              <!-- min width is greater than 320 CSS px -->
              <ul style="width:450px">
              <li>...</li>
              <li>...</li>
              <li>...</li>
              </ul>`} mdxType="CodeSnippet" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.13 Provide correct autocomplete attributes for form fields" mdxType="AccordionItem">
      <p>
        Support users with auto-filling user input by programmatically
        identifying the purpose of the form field using the correct <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input" target="_blank">input type</a> and <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete" target="_blank">autocomplete</a> value.
      </p>
      <Guideline mdxType="Guideline">
        <Do hasCodeSnippet mdxType="Do">
          <p className="ln-u-soft-sides">Always provide the correct <code>autocomplete</code> value</p>
          <CodeSnippet codeFormat="html" code={`
            <!-- The input field is expecting a telephone number, and will present a number keypad on mobile devices -->
            <form>
            <label for="phone">Your phone number</label>
            <input type="tel" id="phone" autocomplete="tel" />
            </form>`} mdxType="CodeSnippet" />
        </Do>
        <Dont hasCodeSnippet mdxType="Dont">
          <p className="ln-u-soft-sides">Don't omit or provide incorrect <code>input</code> types and <code>autocomplete</code> values</p>
          <CodeSnippet codeFormat="html" code={`
            <!-- The input field is expecting an email address formatted like a credit card number -->
            <form>
            <label for="phone">Your phone number</label>
            <input type="email" id="phone" autocomplete="cc-number" />
            </form>`} mdxType="CodeSnippet" />
         </Dont>
      </Guideline>
    </AccordionItem>
  </Accordion>
    </Section>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions about web accessibility or help you with
  your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      